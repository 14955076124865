<template>
  <a-card class="card" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getWmsDataPage"
      :deleteFun="deleteWmsDataInfo"
      :createFun="createWmsDataInfo"
      :updateFun="updateWmsDataInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getWmsDataPage, createWmsDataInfo, updateWmsDataInfo, deleteWmsDataInfo } from '@/api/wms'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: '姓名', name: 'name', fieldType: '', queryType: '%'
        }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '性别',
          dataIndex: 'gender',
          customRender: (text, row, index) => {
            return text === 1 ? '男' : '女'
          }
        },
        {
          title: '手机',
          dataIndex: 'mobile'
        },
        {
          title: '身份证号',
          dataIndex: 'identity_no'
        },
        {
          title: '地址',
          dataIndex: 'address'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ]
      },
      modelTitle: '作业人员',
      formFields: [
        {
          label: '姓名', name: 'name', type: 'default'
        },
        {
          label: '手机', name: 'mobile', type: 'default'
        },
        {
          label: '性别', name: 'gender', type: 'radio', options: [{ value: 1, label: '男' }, { value: 2, label: '女' }]
        },
        {
          label: '身份证号', name: 'identity_no', type: 'default'
        },
        {
          label: '地址', name: 'address', type: 'default'
        }
      ],
      moduleName: 'operator'
    }
  },
  methods: {
    getWmsDataPage,
    createWmsDataInfo,
    updateWmsDataInfo,
    deleteWmsDataInfo
  }
}
</script>
